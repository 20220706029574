import React, { useState, useEffect } from "react";


import 'react-animation/dist/keyframes.css';
import { animations } from 'react-animation';
import { AnimateOnChange } from 'react-animation';

import {Elements, CardElement, PaymentRequestButtonElement, useStripe, useElements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CardSection from './CardSection';
import TextopiaFooter from './TextopiaFooter';

import * as dayjs from "dayjs";

import textopiaSpinner from "./refresh.svg";
import * as config from "./config.js";
import { ThanksWidget } from "./TextopiaInputWidgets.js";
const axios = require('axios');





export function PaymentApp({data, setError}) {

    const [app, setApp] = useState('');
    const [applink, setApplink] = useState('');
    const [settings, setSettings] = useState('');
    const [items, setItems] = useState([]);
    const [saving, setSaving] = useState(false);
    const [thanks, setThanks] = useState(false);
    const [closingMessage, setClosingMessage] = useState(false);
    const [stripePromise, setStripePromise] = useState(null);
    const [clientSecret, setClientSecret] = useState('');



    const lookupApp = async () => {
        if (data && data.applink) {

            setApplink(data.applink);
            setSettings(data.settings);

            let paymentAppInstance = data.paymentAppInstance;
            setApp(paymentAppInstance);
            setItems(paymentAppInstance.lineItems);


            if (paymentAppInstance && !paymentAppInstance.isPaid && !paymentAppInstance.isRefunded && !paymentAppInstance.isPaidOutsideSystem && !paymentAppInstance.isCanceled) {
              //--> preload anything that needs to be populated in advance, like payment methods accepted, etc.
              //--> fetch the client secret from stripe, this is the prep area, keep spinner on until this part is done
              
                let reqObj = { paymentAppInstanceId: paymentAppInstance.objectId}

                let httpObj = {
                  url: config.parseInit.SERVER_URL + "functions/getStripeSecret",
                  method: 'post',
                  headers: {"X-Parse-Application-Id": config.parseInit.APPLICATION_ID, "X-Parse-REST-API-Key": config.parseInit.REST_API_KEY, "Content-Type": "application/json"},
                  data: reqObj,
                }

                let res = await axios(httpObj);
                let myData = res.data && res.data.result; //--> this gets us the client secret, which we'll load into state to use during form submission
                if (myData) setClientSecret(myData);


                //--> setup the promise for strip elements to work
                setStripePromise(loadStripe("pk_live_51I2hamJmvhkDTwKckvZ3DmGLbvujBfoNKbt34b1QqafGx97tjc3WZVNzvDHS7s13mrjKBY3G83eK9L89Sr5aGadx00m9llrbDz",
                        {stripeAccount: paymentAppInstance && paymentAppInstance.locationPtr.stripeAccountId}
                ));

            }
            else if (!paymentAppInstance) {
                //--> some sort of error that the app instance cannot be found
                setError(true);
            } 
            else if (paymentAppInstance.isCanceled) {
                //--> this request has been canceled
                setClosingMessage(`Canceled ${dayjs(paymentAppInstance.canceledOnDate.iso).format("MMM D, YYYY")}`);
            } 
            else if (paymentAppInstance.isRefunded) {
                //--> refunded already
                setClosingMessage(`Refunded ${dayjs(paymentAppInstance.refundedOnDate.iso).format("MMM D, YYYY")}`);
            }
            else if (paymentAppInstance.isPaid) {
                //--> already paid
                setClosingMessage(`Paid ${dayjs(paymentAppInstance.paidOnDate.iso).format("MMM D, YYYY")}`);
            }  
            else if (paymentAppInstance.isPaidOutsideSystem) {
                //--> paid outside system already
                setClosingMessage(`Paid Offline ${dayjs(paymentAppInstance.paidOutsideSystemOnDate.iso).format("MMM D, YYYY")}`);
            } 
        }
    }

    useEffect(() => { lookupApp() }, [data, data.applink]); //--> on every applink change, lookup the app - this is a key driver



    const getTotal = () => {
        let total = 0;

        for (var i = 0; i < items.length; i++) {
            total += items[i].price * items[i].qty;
        }

        return total;
    }

    const [animVal, setAnimVal] = useState({animation: animations.bounceIn});

    var relativeTime = require('dayjs/plugin/relativeTime');
    dayjs.extend(relativeTime);

    //--> UI customization parameters
    let logo = settings && settings.logo;
    let bgColor = settings && settings.branding && settings.branding.bgColor ? settings.branding.bgColor : "#663399";
    let textColor = settings && settings.branding && settings.branding.textColor ? settings.branding.textColor : "#FFFFFF";
    let activeColor = settings && settings.branding && settings.branding.activeColor ? settings.branding.activeColor : "#FFA800";
    let inactiveColor = settings && settings.branding && settings.branding.inactiveColor ? settings.branding.inactiveColor : "#FFFFFF";
    let whiteLogoBg = settings && settings.branding && settings.branding.whiteLogoBg ? "#FFFFFF" : bgColor;

    let colors = {bgColor, textColor, activeColor, inactiveColor, whiteLogoBg, white: "#FFFFFF", black: "#000000", lightgray: "#DCDCDC"};

    let messaging = settings && settings.messaging;

    let intro = messaging && messaging.intro ? messaging.intro : "";

    let format = messaging && messaging.format ? messaging.format : "";
    let allThanks = messaging && messaging.allThanks ? messaging.allThanks : "";
    let allThanksLink = messaging && messaging.allThanksLink ? messaging.allThanksLink : "";
    let allThanksLinkText = messaging && messaging.allThanksLinkText ? messaging.allThanksLinkText : "";

    const [hoverState, setHoverState] = useState(false);
    const toggleHover = () => setHoverState(!hoverState);

    const [paidAmount, setPaidAmount] = useState('');
    const [viewDetails, setViewDetails] = useState(false);


    return (
    <Elements stripe={stripePromise && stripePromise}>

        <div id="app-container" style={{backgroundColor: bgColor, border: "3px solid " + bgColor}}>

            <div id="app-header" >
                  <div className="app-header-content">

                    { (!logo || logo === "") && <div className="font-size-h3 font-weight-bold" style={{color: textColor}}>{applink && applink.locationPtr && applink.locationPtr.name}</div>}

                    { logo && <div className={`full-flex-row mb-2`} style={{backgroundColor: whiteLogoBg}}><img src={logo.url} className="tenant-logo" style={{maxHeight: "65px", maxWidth: "100%"}} /></div>}
                    <div className="full-flex-row font-size-base font-weight-light" style={{padding: '2px 15px 0px 15px', color: textColor}}>{`${applink && applink.locationPtr && applink.locationPtr.address1}${applink && applink.locationPtr && applink.locationPtr.address2 ? ", " + applink.locationPtr.address2 : ""}`}</div>
                    <div className="full-flex-row font-size-base font-weight-light" style={{marginTop: '-5px', color: textColor}}>{`${applink && applink.locationPtr && applink.locationPtr.city}, ${applink && applink.locationPtr && applink.locationPtr.state} ${applink && applink.locationPtr && applink.locationPtr.zipcode}`}</div>
                    { !intro && !thanks && !closingMessage && <div className="text-white full-flex-row font-size-h4 font-weight-bold" style={{padding: '15px 15px 6px 15px'}}>{intro}</div>}
                  </div>
            </div>

            <div id="main-content">

                <div className="">
                    {(!app || !stripePromise) && (!thanks && !closingMessage) && <div className="" style={{textAlign: 'center'}}><img src={textopiaSpinner} height="40" width="40" className="fa-spin" /></div>}

                    {app && stripePromise && !thanks &&
                    <div className="c_fadeIn">
                        <div className="invoice-content">
                            <div className={`${viewDetails ? "mb-15" : "mb-l-40"} mt-l-60`} style={{display: 'flex', textAlign: 'center', flexDirection: 'column'}}>
                                <div className="font-weight-bold font-size-xxxxl" style={{color: textColor}}>{Number(getTotal()).toLocaleString('en-US',{style:'currency',currency:'USD'})}</div>
                                <div className="font-weight-bold font-size-base" style={{color: textColor, marginTop: '-12px'}}>Requested on {dayjs(app.createdAt).format('MMMM D, YYYY')}</div>
                                <div className="full-flex-row" style={{marginTop: '5px'}}>
                                    {!viewDetails && <button className="btn btn-sm font-weight-bold" 
                                                             style={{borderRadius: '50px', backgroundColor: colors.activeColor, color: colors.white}} 
                                                             onClick={() => setViewDetails(true)}>View Details</button>}                                    
                                    {viewDetails && <button className="btn btn-sm font-weight-bold" 
                                                             style={{borderRadius: '50px', backgroundColor: colors.activeColor, color: colors.white}} 
                                                             onClick={() => setViewDetails(false)}>Hide Details</button>}                                                                             
                                </div>
                            </div>
                            

                            {viewDetails && 
                            <div className={`invoice-details`}>
                                <div className="" style={{marginTop: '5px'}}>
                                    <div className="flex-row justify-between mb-15">
                                        {app && app.invoiceNumber && 
                                        <>
                                            <div className="font-weight-bolder font-size-h5" style={{color: textColor, marginTop: '0px'}}>INVOICE</div> 
                                            <div className="font-weight-bolder font-size-h5" style={{color: textColor, marginTop: '0px'}}>#{app && app.invoiceNumber ? app.invoiceNumber : ""}</div>
                                        </>
                                        } 
                                    </div>
                                </div>

                                <div style={{marginTop: '10px'}}>

                                      <div className="flex-row justify-between" style={{marginBottom: '6px'}}>
                                        <div className="column-header column-header-left" style={{color: textColor, width: '150px'}}>ITEM</div>
                                        <div className="column-header column-header-left" style={{color: textColor, width: '50px'}}>QTY</div>
                                        <div className="column-header column-header-right" style={{color: textColor, width: '75x'}}>PRICE</div>
                                      </div>

                                      <div className="separator-lightgray" />

                                      {items && items.length > 0 && items.map((item, index) => (
                                        <div key={`${item.name}_${item.price}_${index}`} className="flex-row justify-between" style={{marginBottom: '6px'}}>
                                          <div className="font-weight-light font-size-base text-left" style={{color: textColor, width: '150px', marginRight: '10px'}}>{item.name}</div>
                                          <div className="font-weight-light font-size-base text-left" style={{color: textColor, width: '50px'}}>{item.qty}</div>
                                          <div className="font-weight-light font-size-base text-right" style={{color: textColor, width: '75px'}}>{Number(item.price).toLocaleString('en-US',{style:'currency',currency:'USD'})}</div>
                                        </div>
                                      ))}

                                      {getTotal() > 0 &&
                                      <>
                                        <div className="separator-lightgray" />

                                        <div className="flex-row justify-between">
                                          <div className="font-weight-bolder font-size-h5 text-left" style={{color: textColor}}>TOTAL DUE</div>
                                          <div className="font-weight-bolder font-size-h5 text-right" style={{color: textColor}}>{Number(getTotal()).toLocaleString('en-US',{style:'currency',currency:'USD'})}</div>
                                        </div>
                                      </>
                                      }
                                      { (getTotal() <= 0 ) &&
                                        <div className="font-weight-bold font-size-base mt-2" style={{color: textColor}}>No items</div>
                                      }

                                </div>
                            </div>
                            }
                        </div>
                        {stripePromise &&
                            <div className="checkout-form" style={{marginTop: viewDetails ? '15px' : '35px'}}>
                                <CheckoutForm app={app} colors={colors} clientSecret={clientSecret} setThanks={setThanks} setAnimVal={setAnimVal} setPaidAmount={setPaidAmount} />
                            </div>
                        }
                    </div>
                    }

                    {thanks && ( //--> payment was submitted
                        <div style={{animation: animations.bounceIn, textAlign: 'center', marginTop: '40px'}}>
                            <div className="font-size-h2 font-weight-bolder" style={{color: textColor}}>Thank you!</div>
                            <EndMessage colors={colors} message={`You paid ${Number(paidAmount/100).toLocaleString('en-US',{style:'currency',currency:'USD'})}`} />                            
                            {allThanks && <ThanksWidget thanks={allThanks} link={allThanksLink} linkText={allThanksLinkText}  colors={colors}/>}
                            
                        </div>
                    )}

                    {!thanks && closingMessage && ( //--> a revisit to this link when it has already been paid/canceled/refunded/etc.
                        <div style={{animation: animations.bounceIn, textAlign: 'center', marginTop: '40px'}}>
                            <EndMessage colors={colors} message={closingMessage} />                            
                            {allThanks && <ThanksWidget thanks={allThanks} link={allThanksLink} linkText={allThanksLinkText}  colors={colors}/>}

                        </div>
                    )}
                </div>

            </div>

            <TextopiaFooter />

            

      </div>

    </Elements>
    )
}

function EndMessage({message, colors}) {
    return (
        <div className="font-size-h3 font-weight-bold mt-10 mb-10 full-flex-row" style={{height: '2.5em', color: colors.textColor, border: '1px solid white', borderRadius: '8px', backgroundColor: colors.activeColor, padding: '5px'}} >
            <span className="mr-10"><i className="demo-icon icon-ok text-white">&#xe802;</i></span> 
            {message}
        </div>
    )
}

function WhiteButtonLink ({saving, colors, onClick, className, children, disabled}) {

    const [hoverState, setHoverState] = useState(false);
    const toggleHover = () => setHoverState(!hoverState);

    return  <button onMouseEnter={() => setHoverState(true)}
                  onMouseLeave={() => setHoverState(false)}
                  style={{color: hoverState ? colors.white : colors.white, backgroundColor: hoverState ? colors.activeColor : saving ? colors.activeColor: colors.activeColor}}
                  className={`btn ${className ? className : ""} `}
                  onClick={onClick}
                  >
                  {children}
            </button>
}


function CheckoutForm ({app, colors, clientSecret, setThanks, setAnimVal, setPaidAmount}) {

    const stripe = useStripe();
    const elements = useElements();
    const [saving, setSaving] = useState(false);
    const [error, setError] = useState('');
    const [paymentRequest, setPaymentRequest] = useState(null);

    useEffect(() => {
        if (stripe) {
          const pr = stripe.paymentRequest({
            country: 'US',
            currency: 'usd',
            total: {
              label: 'Demo total',
              amount: 1099,
            },
            requestPayerName: true,
            requestPayerEmail: true,
          });

          // Check the availability of the Payment Request API.
          pr.canMakePayment().then(result => {
            if (result) {
              setPaymentRequest(pr);
            }
          });

        }
    }, [stripe]);

    if (paymentRequest) {
      return <PaymentRequestButtonElement options={{paymentRequest}} />
    }

    const handleSubmit = async (event) => {

        setSaving(true);

        event.preventDefault();

        if (!stripe || !elements) { //--> if we are not properly loaded just return
            setSaving(false);
            return;
        }

        const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {

                }
            }
        })

        if (result.error) {
            console.log(result.error.message);
            setError(result.error.message);
            setTimeout(() => {
                setSaving(false);
            }, 300);
        } else {
            if (result.paymentIntent.status === 'succeeded') {
                setError('');
                //--> success with fanfare
                //--> update the customer record to indicate this as the most recent activity in that convo
                //--> but customer might actually close this window before a return is recieved, so handle this in the webhook from Stripe
                setPaidAmount(result.paymentIntent.amount);
                setThanks(true);

                setAnimVal({animation: animations.popOut});

                setTimeout(() => {
                    setSaving(false);
                    setAnimVal({animation: animations.bounceIn});
                }, 300)

            }
        }


    }

    return (

        <form>
            <CardElement />
            <WhiteButtonLink className="mt-15 white-button-link font-weight-bolder" saving={saving} colors={colors} onClick={handleSubmit} disabled={!stripe || saving}>
                {!saving && <span>PAY</span> }
                {saving && <img src={textopiaSpinner} className="fa-spin" height="20" width="20" />}                
            </WhiteButtonLink>
            <div style={{height: '50px', marginTop: '5px', color: '#FFFFFF', textAlign: 'center' }}>{error}</div>
        </form>
    )
}
