
import { useState, useEffect } from "react";
import { styles } from 'react-responsive-carousel/lib/styles/carousel.min.css'; //--> this positionoing is importnat to override carousel styles
import './Apps.scss';

//import { FeedbackApp } from "./FeedbackApp";
import { ReviewApp } from "./ReviewApp";
import { PaymentApp } from "./PaymentApp";
import { FeedbackApp } from "./FeedbackApp";
import TextopiaFooter from "./TextopiaFooter";
import * as config from "./config.js";

const axios = require('axios');

export function Apps ({}) {

    const constants = {
        REVIEWS_APP     : "Review Invite Link",
        FEEDBACK_APP    : "Feedback Invite Link",
        PAYMENT_APP     : "Payment Request",        
    }

    let id = (window.location.href).split('/')[((window.location.href).split('/')).length - 1]; 
    if (id && id.includes('?')) {
        id = id.split('?')[0];
    }

    const [error, setError] = useState(false);
    const [appId, setAppId] = useState('');
    const [applink, setApplink] = useState('');
    const [settings, setSettings] = useState('');
    const [data, setData] = useState('');

    useEffect(() => {
        setError( id ? false : true); //--> make sure an id is passed in, otherwise error
        setAppId( id ? id : ''); //--> set the appId for the next stage
    }, [id]);

    
    const lookupApplink = async () => {
        if (appId) {           

            let reqObj = { appId: appId}

            let httpObj = {
              url: config.parseInit.SERVER_URL + "functions/lookupAppInstance",
              method: 'post',
              headers: {"X-Parse-Application-Id": config.parseInit.APPLICATION_ID, "X-Parse-REST-API-Key": config.parseInit.REST_API_KEY, "Content-Type": "application/json"},
              data: reqObj,
            }

            let res = await axios(httpObj);        
            let myData = res.data && res.data.result;
                     
            if (!myData || !myData.applink) setError(true); //--> make sure appId translates to a real app, otherwise error
            else {
                setError(false);
                setData(myData);
            }
            
        } 
    };

    useEffect(() => {
        lookupApplink();        
    }, [appId])

    const lockScroll = () => {
      const body = document.querySelector('body');
      let scrollPosition = 0;

      scrollPosition = window.pageYOffset;
      body.style.overflow = 'hidden';
      body.style.position = 'fixed';
      body.style.top = `-${scrollPosition}px`;
      body.style.width = '100%';    
      body.style.height = '100vh';
      
      return scrollPosition;
    }

    const unlockScroll = (pos) => {
      const body = document.querySelector('body');
      let scrollPosition = pos ? pos : 0;

      body.style.removeProperty('overflow');
      body.style.removeProperty('position');
      body.style.removeProperty('top');
      body.style.removeProperty('width');
      window.scrollTo(0, scrollPosition);
    }

    useEffect(() => {
        let pos;
        pos = lockScroll();
        return (() => {
            unlockScroll(pos);
        })
    })


    

    return (
            <>
              {error && (
                <div id="app-container" className="bg-primary">              
                
                    <div id="app-header">
                          <div className="app-header-content pt-5">
                          </div>                    
                    </div>

                    <div id="main-content" className="px-8 px-lg-15">                                        
                        <div className="text-white font-size-h1 font-weight-bold text-center">
                            There seems to be an error!
                        </div>
                    </div>

                    <TextopiaFooter />

                  </div>
              )}
              
              {!error && data && data.applink && data.applink.appType === constants.FEEDBACK_APP && (
                <FeedbackApp data={data} setError={setError} />                
              )}

              {!error && data && data.applink && data.applink.appType === constants.REVIEWS_APP && (
                <ReviewApp data={data} setError={setError} />               
              )} 

              {!error && data && data.applink && data.applink.appType === constants.PAYMENT_APP && (
                <PaymentApp data={data} setError={setError} />
              )} 
                          
         </>

    )
}

export default Apps;
