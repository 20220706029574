import React, { useState, useEffect } from "react";


export function StarsWidget ({myVal, name, colors, setMyVal}) {
    const setVal = (value) => {
        setMyVal(value);
    }
    const arr = [1,2,3,4,5];

    return (
        <div className="full-flex-row">
            {arr.map ( (item, index) =>
                <StarItem key={index} 
                          myVal={myVal} 
                          name={name} 
                          value={item} 
                          setVal={setVal} 
                          last={index === arr.length - 1 ? true : false} 
                          colors={colors} />
            )}
        </div>
    )
}

function StarItem ({myVal, name, value, setVal, last, colors}) {
    const [hoverState, setHoverState] = useState(false);

    return <i className={`demo-icon`} 
              onMouseEnter={() => setHoverState(true)} 
              onMouseLeave={() => setHoverState(false)} 
              style={{fontSize: '1.25em', cursor: 'pointer', color: hoverState ? colors.activeColor : (myVal >= value) ? colors.activeColor: colors.lightgray}} 
              onClick={() => setVal(value)}
              >&#xe801;</i> 

}

export function WriteInWidget ({setMyVal, name, colors}) {

    const setVal = (e) => {
        setMyVal(e.target.value);       
    }

    return (
        <div className="full-flex-row">
            <textarea   row="4" 
                        className={`font-family-base font-size-lg font-weight-bold mx-1`}                         
                        onChange={(e) => setVal(e)} 
                        style={{
                                resize: 'none', 
                                margin: '0px 3px 0px 3px',
                                height: '90px',
                                outline: 'none',
                                color: colors ? '#5E6278' : '#5E6278',
                                border: '0',
                                width: '90%',
                                borderRadius: '8px',
                                padding: '10px 15px 10px 15px',
                                backgroundColor: '#f0f0f0',

                              }} />
        </div>
    )
}

export function YesNoWidget ({myVal, setMyVal, name, colors}) {

    const [hoverStateYes, setHoverStateYes] = useState(false);
    const [hoverStateNo, setHoverStateNo] = useState(false);

    const setVal = (value) => {
        setMyVal(value);
    }

    return (
        <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-center">
            <span className={`btn ${myVal === "No" ? "btn-shadow" : ""} btn-xs btn-icon mr-10`} 
                  onClick={() => setVal('No')}
                  onMouseEnter={() => setHoverStateNo(true)} 
                  onMouseLeave={() => setHoverStateNo(false)} 
                  style={{fontSize: '0.8em', backgroundColor: hoverStateNo ? colors.activeColor : (myVal === "No") ? colors.activeColor: colors.lightgray, border: '0px solid ' + colors.white }} 
                  >
                    <i className="demo-icon icon-thumbs-up" style={{color: hoverStateNo ? colors.white : (myVal === "No") ? colors.white: colors.white}}>&#xe80b;</i>
            </span>
            <span className={`btn ${myVal === "Yes" ? "btn-shadow" : ""} btn-xs btn-icon`} 
                  onClick={() => setVal('Yes')}
                  onMouseEnter={() => setHoverStateYes(true)} 
                  onMouseLeave={() => setHoverStateYes(false)} 
                  style={{fontSize: '0.8em', backgroundColor: hoverStateYes ? colors.activeColor : (myVal === "Yes") ? colors.activeColor: colors.lightgray, border: '0px solid ' + colors.white }} 
                  >
                    <i className="demo-icon icon-thumbs-up" style={{color: hoverStateYes ? colors.white : (myVal === "Yes") ? colors.white: colors.white}}>&#xe80a;</i>
            </span>            
        </div>
    )
}

export function SmileysWidget ({myVal, setMyVal, name, colors}) {
    const setVal = (value) => {
        setMyVal(value);
    }
    const arr = [1,2,3,4,5];

    return (
        <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-center">
            {arr.map ( (item, index) =>
                <SmileyItem key={index} myVal={myVal} name={name} value={item} setVal={setVal} last={index === arr.length - 1 ? true : false} colors={colors} />
            )}
        </div>
    )
}

function SmileyItem ({myVal, name, value, setVal, last, colors}) {

    const [hoverState, setHoverState] = useState(false);   

    return <div onMouseEnter={() => setHoverState(true)} 
                onMouseLeave={() => setHoverState(false)} 
                style={{        borderRadius: '50%', 
                                border: '0px solid ' + (hoverState ? colors.activeColor : (myVal === value) ? colors.activeColor: colors.lightgray),
                                backgroundColor: (hoverState ? colors.activeColor : (myVal === value) ? colors.activeColor: colors.lightgray), 
                                fontSize: '.7em',
                                padding: '4px', 
                                cursor: 'pointer', 
                                color: hoverState ? colors.white : (myVal === value) ? colors.white: colors.white
                       }} 
                className={`${!last && "mr-10"}`} 
                onClick={() => setVal(value)}>
              {value === 1 && <i className="demo-icon">&#xe80d;</i>}
              {value === 2 && <i className="demo-icon">&#xe804;</i>}
              {value === 3 && <i className="demo-icon">&#xe806;</i>}
              {value === 4 && <i className="demo-icon">&#xe805;</i>}
              {value === 5 && <i className="demo-icon">&#xe812;</i>}              
           </div>
}


export function ScoreWidget ({myVal, setMyVal, name, colors}) {
    const setVal = (value) => {
        setMyVal(value);
    }
    const arr = [0,1,2,3,4,5,6,7,8,9,10];

    return (
        <div className="full-flex-row">
        {arr.map ( (item, index) => 
            <ScoreItem key={index} 
                       myVal={myVal} 
                       name={name} 
                       value={item} 
                       setVal={setVal} 
                       last={index === arr.length - 1 ? true : false} 
                       colors={colors} />
        )}                       
        </div>
    )
} 

function ScoreItem ({myVal, name, value, setVal, last, colors}) {
    const [hoverState, setHoverState] = useState(false);
    
    return <span onMouseEnter={() => setHoverState(true)} 
                 onMouseLeave={() => setHoverState(false)} 
                 style={{   backgroundColor: hoverState ? colors.activeColor : ((myVal != '') && (myVal >= value)) ? colors.activeColor : colors.lightgray,
                            color:  hoverState ? colors.white : (myVal != '' && myVal >= value) ? colors.white : colors.white,
                            border: "0px solid " + colors.white,
                       }} 
                 className={`cursor-pointer label label-xl label-circle font-weight-bolder font-size-base ${!last && "mr-1"} `} 
                 onClick={() => setVal(value)}>{value}</span>
}

export function ChoicesWidget ({myVal, setMyVal, name, options, colors}) {

    const choices = options && options.split(",");
    const setVal = (e) => {
        setMyVal(e.target.value);
    }

    return (
        <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-center">
            <div className="radio-list">
                {choices && choices.map( (choice, index) => 
                    <label key={index} className={`font-size-h6 font-weight-light radio radio-${colors ? "gray-600" : "warning"} ${index < choices.length - 1 ? "mb-5" : ""}`} style={{color: colors ? colors.textColor : "white"}}>
                        <input type="radio" name={name} value={choice.trim()} onChange={(e) => setVal(e)} />
                        <span style={{backgroundColor: myVal === choice.trim() ? colors.activeColor : "#f0f0f0"}}></span>
                        {choice.trim()}
                    </label>
                 )}
             </div>
        </div>
    )
}

export function MultipleChoicesWidget ({myVal, name, options, colors, setMyVal}) {

    const choices = options && options.split(",");
    const [selected, setSelected] = useState([]);
    
    const setVal = (e) => {
        let mySel = selected;
        if (e.target.checked) {
            if (!mySel.find(item => item === e.target.name)) mySel.push(e.target.name);            
        }
        else if (!e.target.checked) {
            mySel = mySel.filter(item => item != e.target.name);
        }

        setSelected(mySel);
        setMyVal(mySel);
    }

    return (
        <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-center">
            <div className="checkbox-list">
                {choices && choices.map( (choice, index) => 
                    <label key={index} className={`checkbox font-size-h6 font-weight-light ${index < choices.length - 1 ? "mb-5" : ""} `} style={{color: colors ? colors.textColor : "white"}}>
                        <input type="checkbox" name={choice.trim()} onChange={(e) => setVal(e)}/>
                        <span></span>
                        {choice.trim()}
                    </label>
                 )}
             </div>
        </div>
    )
}

export function ThanksWidget({thanks, link, linkText, colors}) {
    return (
        <div className="all-thanks-box">
            <div className="font-size-h6 font-weight-normal mb-10" style={{color: colors.textColor}}>{thanks}</div>
            {link && linkText && <a href={link} className="all-thanks-button font-size-h5" style={{color: 'white', backgroundColor: colors.activeColor}}>{linkText}</a>}
        </div> 
    )
}



export function WhiteButtonLink ({saving, colors, onClick, className, setThanks, children}) {

    const [hoverState, setHoverState] = useState(false);
    const toggleHover = () => setHoverState(!hoverState);

    const handleClick = () => {
        setThanks(true);
        window.open(onClick, '_blank'); //--> open up the destination in a new window/tab
    }

    return  <button onMouseEnter={() => setHoverState(true)} 
                  onMouseLeave={() => setHoverState(false)} 
                  style={{borderRadius: '8px', color: hoverState ? colors.white : colors.white, backgroundColor: hoverState ? colors.activeColor : saving ? colors.activeColor: colors.activeColor}} 
                  className={`${className ? className : ""} `}
                  onClick={() => handleClick()}>
                  {children}
            </button>
}


