import React, { useState, useEffect } from "react";

import 'react-animation/dist/keyframes.css';
import { animations } from 'react-animation';

import { Carousel } from 'react-responsive-carousel';
import textopiaSpinner from "./refresh.svg";
import TextopiaFooter from "./TextopiaFooter";

import * as dayjs from "dayjs";
import textopiaLogo from "./logo_white.svg";
import * as config from "./config.js";
import {WriteInWidget, StarsWidget, ThanksWidget, WhiteButtonLink} from "./TextopiaInputWidgets.js";

const axios = require('axios');

export function ReviewApp({data, setError}) {

    const [app, setApp] = useState('');
    const [sites, setSites] = useState('');
    const [siteNum, setSiteNum] = useState('');
    const [saving, setSaving] = useState(false);
    const [thanks, setThanks] = useState(false);
    const [applink, setApplink] = useState('');
    const [settings, setSettings] = useState('');
    const [stars, setStars] = useState('');
    const [reviewText, setReviewText] = useState('');

    const prepApp = async () => {
        if (data && data.applink) {

            setApplink(data.applink);
            setSettings(data.settings);

            let reviewAppInstance = data.reviewAppInstance;
                        
            if (data.reviewAppInstance) {
                setApp(data.reviewAppInstance);

                //--> this is the prep work to make sure the forms work, preload stuff here and keep spinner on until this is loaded
                
                let mySites = data.sites; //--> preloaded by the cloud function
                if (mySites) { //--> Always start from the first site in the list, user can go back and forth at will between review site links
                    setSites(mySites);
                    setSiteNum(1); //--> starting from the beginning of the sites array
                }
                else {
                    setError(true) //--> no sites found, set an error, review invite sent without review app config
                }
            }
            else {
                //--> some sort of error that the app instance cannot be found
                setError(true);
            }
        }
    }

    useEffect(() => { prepApp() }, [data, data.applink]); //--> on every applink change, lookup the app - this is a key driver

    const handleSubmit = async () => {       
        setSaving(true);

        let reqObj = { values: {stars: stars, comments: reviewText}, app: app, customer: app.customerPtr} //--> review can only come for Textopia.ai site, we already know the site here

        let httpObj = {
          url: config.parseInit.SERVER_URL + "functions/saveReviewFromApp",
          method: 'post',
          headers: {"X-Parse-Application-Id": config.parseInit.APPLICATION_ID, "X-Parse-REST-API-Key": config.parseInit.REST_API_KEY, "Content-Type": "application/json"},
          data: reqObj,
        }

        let res = await axios(httpObj);
        
        if (res && res.data && res.data.result && res.data.result.success) {
            setThanks(true);

            setAnimVal({animation: animations.popOut});


            setTimeout(() => {
                setSaving(false);
                setAnimVal({animation: animations.bounceIn});
            }, 300)
    
        } else {
            setError(true);
        }
        
    }

    const [animVal, setAnimVal] = useState({animation: animations.bounceIn});

    var relativeTime = require('dayjs/plugin/relativeTime');
    dayjs.extend(relativeTime);

    //--> UI customization parameters
    let logo = settings && settings.logo;
    let bgColor = settings && settings.branding && settings.branding.bgColor ? settings.branding.bgColor : "#663399";
    let textColor = settings && settings.branding && settings.branding.textColor ? settings.branding.textColor : "#FFFFFF";
    let activeColor = settings && settings.branding && settings.branding.activeColor ? settings.branding.activeColor : "#FFA800";
    let inactiveColor = settings && settings.branding && settings.branding.inactiveColor ? settings.branding.inactiveColor : "#FFFFFF";
    let whiteLogoBg = settings && settings.branding && settings.branding.whiteLogoBg ? "#FFFFFF" : bgColor;

    let colors = {bgColor, textColor, activeColor, inactiveColor, whiteLogoBg, white: "#FFFFFF", black: "#000000", lightgray: "#DCDCDC"};

    let messaging = settings && settings.messaging;    

    let intro = messaging && messaging.intro ? messaging.intro : "";

    let format = messaging && messaging.format ? messaging.format : "";
    let allThanks = messaging && messaging.allThanks ? messaging.allThanks : "";
    let allThanksLink = messaging && messaging.allThanksLink ? messaging.allThanksLink : "";
    let allThanksLinkText = messaging && messaging.allThanksLinkText ? messaging.allThanksLinkText : "";
    
    const [hoverState, setHoverState] = useState(false);
    const toggleHover = () => setHoverState(!hoverState); 

    const lockScroll = () => {
      const body = document.querySelector('body');
      let scrollPosition = 0;

      scrollPosition = window.pageYOffset;
      body.style.overflow = 'hidden';
      body.style.position = 'fixed';
      body.style.top = `-${scrollPosition}px`;
      body.style.width = '100%';    
      body.style.height = '100vh';
      
      return scrollPosition;
    }

    const unlockScroll = (pos) => {
      const body = document.querySelector('body');
      let scrollPosition = pos ? pos : 0;

      body.style.removeProperty('overflow');
      body.style.removeProperty('position');
      body.style.removeProperty('top');
      body.style.removeProperty('width');
      window.scrollTo(0, scrollPosition);
    }

    useEffect(() => {
        let pos;
        pos = lockScroll();
        return (() => {
            unlockScroll(pos);
        })
    })



    var site;
    
    return (
    <>
        
        <div id="app-container" style={{backgroundColor: bgColor, border: "3px solid " + bgColor}}>                              

            <div id="app-header" >
                  <div className="app-header-content pt-5">

                    { (!logo || logo === "") && <div className="font-size-h3 font-weight-bold" style={{color: textColor}}>{applink && applink.locationPtr && applink.locationPtr.name}</div>}

                    { logo && <div className={`full-flex-row mb-2`} style={{backgroundColor: whiteLogoBg}}><img src={logo.url} className="tenant-logo" style={{maxHeight: "65px", maxWidth: "100%"}} /></div>}
                    
                    { intro && <div className="full-flex-row font-size-base font-weight-light" style={{color: textColor}}>{intro}</div>}
                  </div>   
                  
            </div>


            <div id="main-content" style={{padding: '0em 3em 0em 3em'}}>                    

                    <div className="font-size-h3 font-weight-bold text-center" style={{color: colors.textColor}}>
                        {(!sites || !app) && <div className="" style={{textAlign: 'center'}}><img src={textopiaSpinner} height="40" width="40" className="fa-spin" /></div>}

                        {sites && !thanks && (
                        <>
                            <Carousel>
                              
                            {sites.map((site, index) => (

                            <div key={index} style={{backgroundColor: bgColor, height: '350px'}}>  


                                    {site.reviewSitePtr.title === "Textopia.ai" && (

                                          
                                          <form>

                                            <div style={animVal}>
                                                <img src={textopiaLogo} className="app-logo" style={{width: '150px'}}/>

                                                <div className="mb-3" style={{marginBottom: '9px'}}><StarsWidget myVal={stars} setMyVal={setStars} name="stars" colors={colors} /></div>
                                                <div className="mb-15"><WriteInWidget setMyVal={setReviewText} name="comments" colors={colors} /></div>

                                                <button type="button"
                                                        className={`white-button-link font-weight-bold font-size-h5 mb-20 c_fadeIn ${(stars === "" && reviewText === "")}`}                                                        
                                                        onClick={() => handleSubmit()}
                                                        disabled={((stars === "" && reviewText === ""))} 
                                                        onMouseEnter={() => setHoverState(true)} 
                                                        onMouseLeave={() => setHoverState(false)} 
                                                        style={{borderRadius: '8px', width: '125px', color: hoverState ? colors.white : colors.white, backgroundColor: (hoverState || saving) ? colors.activeColor : colors.activeColor}}                                                     
                                                        >
                                                        {!saving && <span>Save</span> }
                                                        {saving && <img src={textopiaSpinner} className="fa-spin" height="20" width="20" />}
                                                        </button>

                                            </div>
                                            

                                          </form>

                                    ) //--> Textopia review form
                                    }

                                    {site.reviewSitePtr.title !== "Textopia.ai" && (
                                        <div style={{animation: animations.fadeIn}}>
                                            <div><img src={site.reviewSitePtr.logo.url} className="rounded mb-3" style={{borderRadius: '8px', margin: '45px 0px 9px 0px', opacity: 1, width: "50px"}} /></div>
                                            <div>Use <b className="font-size-h2">{site.reviewSitePtr.title}</b> to leave your review?</div>
                                            <WhiteButtonLink saving={saving} onClick={site.reviewGenerationUrl} setThanks={setThanks} className="white-button-link short-width mt-40 font-size-h4 font-weight-bold" colors={colors} >Yes</WhiteButtonLink> 
                                        </div>
                                    )}                                   

                            </div>
                            )) //--> carousel with one or more sites in it (Textopia site will always be there, unless paused by user, cannot be deleted, only paused)
                            }
                            
                            </Carousel>   
                        </>
                        ) //--> rendering either the sites carousel or the thanks message, if review submitted on Textopia
                        }

                        {thanks && ( //--> a review was left on Textopia
                            <div style={{animation: animations.bounceIn}}>
                                <div className="font-size-h1 font-weight-bolder" style={{color: textColor}}>Thank you!</div>                            
                                {allThanks && <ThanksWidget thanks={allThanks} link={allThanksLink} linkText={allThanksLinkText}  colors={colors}/>}                                                                
                            </div>
                        )}
                    </div>

            </div>

            <TextopiaFooter />

      </div>

    </>
    )
}

