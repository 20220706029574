import React from 'react';
import ReactDOM from 'react-dom';

import Apps from './Apps';

ReactDOM.render(
  <React.StrictMode>
    <Apps />
  </React.StrictMode>,
  document.getElementById('root')
);
