import React, { useState, useEffect } from "react";

import 'react-animation/dist/keyframes.css';
import { animations } from 'react-animation';

import textopiaSpinner from "./refresh.svg";
import TextopiaFooter from "./TextopiaFooter";

import * as dayjs from "dayjs";
import textopiaLogo from "./logo_white.svg";
import * as config from "./config.js";
import {ThanksWidget, YesNoWidget, StarsWidget, WriteInWidget, ChoicesWidget, MultipleChoicesWidget, ScoreWidget, SmileysWidget} from "./TextopiaInputWidgets.js";
import Div100vh from 'react-div-100vh';
import { use100vh } from 'react-div-100vh';

const axios = require('axios');

export function FeedbackApp({data, setError}) {

    const constants = {
        FORMAT_STARS:               "Stars",
        FORMAT_CHOICES:             "Choices",
        FORMAT_MULTIPLE_CHOICES:    "Multiple Choices",
        FORMAT_WRITE_IN:            "Write-In",
        FORMAT_YES_NO:              "Yes/No",
        FORMAT_SCORE:               "Score",
        FORMAT_SMILEYS:             "Smileys",

    }

    const [app, setApp] = useState('');
    const [questions, setQuestions] = useState('');
    const [questionNum, setQuestionNum] = useState('');
    const [saving, setSaving] = useState(false);

    const [applink, setApplink] = useState('');
    const [settings, setSettings] = useState('');
    const [myVal, setMyVal] = useState('');

    const prepApp = async () => {
        if (data && data.applink) {

            setApplink(data.applink);
            setSettings(data.settings);
                        
            if (data.feedbackAppInstance) {
                setApp(data.feedbackAppInstance);
            }

            if (data.feedbackAppInstance && !data.feedbackAppInstance.isComplete) {
                
                let httpObj = {
                  url: config.parseInit.SERVER_URL + "functions/getFeedbackQuestions",
                  method: 'post',
                  headers: {"X-Parse-Application-Id": config.parseInit.APPLICATION_ID, "X-Parse-REST-API-Key": config.parseInit.REST_API_KEY, "Content-Type": "application/json"},
                  data: {feedbackAppInstanceId: data.feedbackAppInstance.objectId},
                }

                let res = await axios(httpObj);

                let myQuestions = res && res.data && res.data.result;

                if (myQuestions) { //--> figure out if this feedback was partially answered before and start from the right question
                    setQuestions(myQuestions);

                    if (data.feedbackAppInstance.questions && data.feedbackAppInstance.questions.length > 0) {
                        setQuestionNum(data.feedbackAppInstance.questions.length + 1); //--> starting from the next question in the survey
                    }
                    else {
                        setQuestionNum(1); //--> starting from the beginning of the survey
                    }
                }
                else {
                    setError(true); //--> no questions found error, feedback invite sent without questions being setup or active
                }
            }
            else if (!data.feedbackAppInstance) { //--> some sort of error so that we cannot find the app instance
                setError(true);
            }
            else {
                setQuestionNum(-2); //--> already completed survey, so forward to thankyou message
            }
                
        }
    }

    useEffect(() => { prepApp() }, [data, data.applink]); //--> on every applink change, lookup the app - this is a key driver


    const handleSubmit = async () => {       

        setSaving(true);
        setHoverState(false);
        
        //--> update the customer record to indicate this as the most recent activity in that convo
        if (app.customerPtr && !app.isFeedbackSubmitted) {
            let httpObj1 = {
              url: config.parseInit.SERVER_URL + "functions/setFeedbackAsLastConvoEntry",
              method: 'post',
              headers: {"X-Parse-Application-Id": config.parseInit.APPLICATION_ID, "X-Parse-REST-API-Key": config.parseInit.REST_API_KEY, "Content-Type": "application/json"},
              data: {feedbackAppInstanceId: app.objectId},
            }

            await axios(httpObj1);
        }

        app.isFeedbackSubmitted = true;
        app.feedbackSubmittedOnDate = new Date();                       
             
        let ques = app.questions || [];
        let question = questions[questionNum - 1]
        ques.push({ id: question.objectId,
                    question: question,
                    positionInList: questionNum,
                    format: question.format,
                    body: question.body,
                    response: myVal
                 })

        app.questions = ques;
        
        //--> create an entry for this question in the FeebackQuestionResponse table for per-question dashboard

        let newQuesObj = {
            feedbackAppInstanceId: app.objectId,
            question: question.objectId,
            format: question.format,
            body: question.body,
            response: myVal.toString(),
            submittedOnDate: new Date()
        }
        let httpObj2 = {
          url: config.parseInit.SERVER_URL + "functions/saveNewQuestionResponse",
          method: 'post',
          headers: {"X-Parse-Application-Id": config.parseInit.APPLICATION_ID, "X-Parse-REST-API-Key": config.parseInit.REST_API_KEY, "Content-Type": "application/json"},
          data: newQuesObj,
        }

        await axios(httpObj2);                
        
        let httpObj3 = {
          url: config.parseInit.SERVER_URL + "functions/saveFeedbackAppInstance",
          method: 'post',
          headers: {"X-Parse-Application-Id": config.parseInit.APPLICATION_ID, "X-Parse-REST-API-Key": config.parseInit.REST_API_KEY, "Content-Type": "application/json"},
          data: {feedbackAppInstanceId: app.objectId, questions: ques, isComplete: questions && (questionNum >= questions.length)}
        }

        let savedAppInstance = await axios(httpObj3);
        //setApp(savedAppInstance);

        //--> update question stats
        let httpObj4 = {
          url: config.parseInit.SERVER_URL + "functions/saveQuestionStats",
          method: 'post',
          headers: {"X-Parse-Application-Id": config.parseInit.APPLICATION_ID, "X-Parse-REST-API-Key": config.parseInit.REST_API_KEY, "Content-Type": "application/json"},
          data: {feedbackAppInstanceId: app.objectId, values: {question: question, questionNum: questionNum, val: myVal}}
        }

        await axios(httpObj4);
      
        setAnimVal({animation: animations.popOut});
        setTimeout(() => {
            setSaving(false);
            setAnimVal({animation: animations.bounceIn});
            setQuestionNum(questions && questions.length > questionNum ? questionNum + 1 : -1);
        }, 300)

    }
    
    const [animVal, setAnimVal] = useState({animation: animations.bounceIn});

    var relativeTime = require('dayjs/plugin/relativeTime');
    dayjs.extend(relativeTime);

    let logo = settings && settings.logo;
    //console.log(logo);
    let bgColor = settings && settings.branding && settings.branding.bgColor ? settings.branding.bgColor : "#663399";
    let textColor = settings && settings.branding && settings.branding.textColor ? settings.branding.textColor : "#FFFFFF";
    let activeColor = settings && settings.branding && settings.branding.activeColor ? settings.branding.activeColor : "#FFA800";
    let inactiveColor = settings && settings.branding && settings.branding.inactiveColor ? settings.branding.inactiveColor : "#FFFFFF";
    let whiteLogoBg = settings && settings.branding && settings.branding.whiteLogoBg ? "#FFFFFF" : bgColor;

    let colors = {bgColor, textColor, activeColor, inactiveColor, whiteLogoBg, white: "#FFFFFF", black: "#000000", lightgray: "#DCDCDC"};



    let messaging = settings && settings.messaging;    

    let intro = messaging && messaging.intro ? messaging.intro : "";

    let format = messaging && messaging.format ? messaging.format : "";
    let allThanks = messaging && messaging.allThanks ? messaging.allThanks : "";
    let allThanksLink = messaging && messaging.allThanksLink ? messaging.allThanksLink : "";
    let allThanksLinkText = messaging && messaging.allThanksLinkText ? messaging.allThanksLinkText : "";

    let posThanks = messaging && messaging.posThanks ? messaging.posThanks : "";
    let posThanksLink = messaging && messaging.posThanksLink ? messaging.posThanksLink : "";
    let posThanksLinkText = messaging && messaging.posThanksLinkText ? messaging.posThanksLinkText : "";

    let passThanks = messaging && messaging.passThanks ? messaging.passThanks : "";
    let passThanksLink = messaging && messaging.passThanksLink ? messaging.passThanksLink : "";
    let passThanksLinkText = messaging && messaging.passThanksLinkText ? messaging.passThanksLinkText : "";

    let negThanks = messaging && messaging.negThanks ? messaging.negThanks : "";
    let negThanksLink = messaging && messaging.negThanksLink ? messaging.negThanksLink : "";
    let negThanksLinkText = messaging && messaging.negThanksLinkText ? messaging.negThanksLinkText : "";


    const [hoverState, setHoverState] = useState(false);
    const toggleHover = () => setHoverState(!hoverState); 
    
    const lockScroll = () => {
      const body = document.querySelector('body');
      let scrollPosition = 0;

      scrollPosition = window.pageYOffset;
      body.style.overflow = 'hidden';
      body.style.position = 'fixed';
      body.style.top = `-${scrollPosition}px`;
      body.style.width = '100%';    
      body.style.height = '100vh';
      
      return scrollPosition;
    }

    const unlockScroll = (pos) => {
      const body = document.querySelector('body');
      let scrollPosition = pos ? pos : 0;

      body.style.removeProperty('overflow');
      body.style.removeProperty('position');
      body.style.removeProperty('top');
      body.style.removeProperty('width');
      window.scrollTo(0, scrollPosition);
    }

    useEffect(() => {
        let pos;
        pos = lockScroll();
        return (() => {
            unlockScroll(pos);
        })
    })

    return (
   
        <div id="app-container" style={{backgroundColor: bgColor, border: "3px solid " + bgColor}}>              
                
            <div id="app-header" >
                  <div className="app-header-content pt-5">

                    { (!logo || logo === "") && <div className="font-size-h3 font-weight-bold" style={{color: textColor}}>{applink && applink.locationPtr && applink.locationPtr.name}</div>}

                    { logo && <div className={`full-flex-row mb-2`} style={{backgroundColor: whiteLogoBg}}><img src={logo.url} className="tenant-logo" style={{maxHeight: "65px", maxWidth: "100%"}} /></div>}
                    
                    { intro && <div className="full-flex-row font-size-base font-weight-light" style={{color: textColor}}>{intro}</div>}
                  </div>   
                  
            </div>



            <div id="main-content" style={{padding: '0em 3em 0em 3em'}}>                    

                    <div className="text-white font-size-h1 font-weight-bold text-center">
                        {!questions && !(app && app.isComplete) && <div className="" style={{textAlign: 'center'}}><img src={textopiaSpinner} height="40" width="40" className="fa-spin" /></div>}
                                              
                        { questions && questionNum > 0 && questionNum < questions.length + 1 && (

                          
                              <form noValidate>

                                {questions[questionNum - 1] && (
                                <div style={animVal} >
                                    <div className="font-size-h5 mb-20" style={{color: colors.textColor}} >{questions[questionNum - 1].body}</div>

                                    {questions[questionNum - 1].format === constants.FORMAT_YES_NO && ( <YesNoWidget name="val" myVal={myVal} setMyVal={setMyVal} colors={colors} /> )}
                                    {questions[questionNum - 1].format === constants.FORMAT_STARS && ( <StarsWidget name="val" myVal={myVal} setMyVal={setMyVal} colors={colors} /> )}
                                    {questions[questionNum - 1].format === constants.FORMAT_WRITE_IN && ( <WriteInWidget lockScroll={lockScroll} name="val" myVal={myVal} setMyVal={setMyVal} colors={settings && settings.branding ? colors : undefined}  /> )}
                                    {questions[questionNum - 1].format === constants.FORMAT_CHOICES && ( <ChoicesWidget name="val" myVal={myVal} setMyVal={setMyVal}  options={questions[questionNum - 1].multipleChoices} colors={settings && settings.branding ? colors : undefined} /> )}
                                    {questions[questionNum - 1].format === constants.FORMAT_MULTIPLE_CHOICES && ( <MultipleChoicesWidget name="val" myVal={myVal} setMyVal={setMyVal}  options={questions[questionNum - 1].multipleChoices} colors={settings && settings.branding ? colors : undefined} /> )}
                                    {questions[questionNum - 1].format === constants.FORMAT_SCORE && ( <ScoreWidget name="val" myVal={myVal} setMyVal={setMyVal}  colors={colors} />)}
                                    {questions[questionNum - 1].format === constants.FORMAT_SMILEYS && ( <SmileysWidget name="val" myVal={myVal} setMyVal={setMyVal} colors={colors} /> )}
                                    
                                    
                                    <div className="mt-next-btn">
                                    
                                    <button type="button"
                                            className={`btn btn-icon font-weight-bolder font-size-base short-width`}
                                            onClick={() => handleSubmit()}
                                            //disabled={formik.values.val === "" || (formik.values.val && formik.values.val.length < 1)}
                                            style={{borderRadius: '50px', backgroundColor: (saving || hoverState) ? colors.activeColor : colors.activeColor, color: (saving || hoverState) ? colors.white : colors.white}} 
                                            onMouseEnter={() => setHoverState(true)}                                         
                                            onMouseLeave={() => setHoverState(false)} 
                                            >
                                          {!saving && <span>Send</span> }
                                          {saving && <img src={textopiaSpinner} className="fa-spin" height="20" width="20" />}
                                    </button>
                                    
                                    
                                    </div>

                                </div>
                                )}
                                

                              </form>

                           

                        )}

                        {questionNum < 0 && ( //--> all answered or user returned to this link after completion sets questionNum to -1
                            <div style={{animation: animations.bounceIn}}>
                                <div className="font-size-h4 font-weight-bolder" style={{color: colors.textColor}}>Thank you!</div>
                                {questionNum < -1 && app && app.isComplete && app.feedbackSubmittedOnDate && <div className="font-size-base font-weight-light mt-1" style={{color: colors.textColor}}>You sent feedback {dayjs(app.feedbackSubmittedOnDate.iso).fromNow()}</div>  }       

                                {format && (format === "Same") && allThanks &&
                                <ThanksWidget thanks={allThanks} link={allThanksLink} linkText={allThanksLinkText} colors={colors}/>
                                }     
                                                                
                                {format && (format === "Different") && app && app.sentiment && (       
                                    app.sentiment.nps > 0.7 ? 
                                    <ThanksWidget thanks={posThanks} link={posThanksLink} linkText={posThanksLinkText} colors={colors}/>
                                    : 
                                    app.sentiment.nps > 0.4 ? 
                                    <ThanksWidget thanks={passThanks} link={passThanksLink} linkText={passThanksLinkText}  colors={colors}/>                                    
                                    : 
                                    <ThanksWidget thanks={negThanks} link={negThanksLink} linkText={negThanksLinkText}  colors={colors}/>                                     
                                )}
                                 
                                
                            </div>
                        )}
                    </div>

            </div>

            <TextopiaFooter colors={colors}/>

      </div>
 
    )
}


