import React from "react";

import textopiaLogo from "./Grayscale.png";
export function TextopiaFooter ({colors}) {

    return (
        <div id="app-footer">
            <div className="app-footer-content">
                <div className="font-size-lg font-weight-normal app-logo" style={{marginBottom: '0px', color: "rgb(145,145,145)"}}>Powered By</div>
                <img src={textopiaLogo} width="90" className="app-logo"/>
            </div>
        </div>
    )
}

export default TextopiaFooter;